.card_wrapper {
  position: relative;
  transition: 0.2s;
  overflow: hidden;
  transition: 0.15s ease-in-out;
  margin-top: 20px;
  border-radius: 10px;
}

.card_wrapper:after {
  content: '';
  transition: 0.2s all ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  left: 50%;
  height: 5px;
  bottom: 0px;
  transition: 0.2s all ease;
  width: 0;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 10px;
}

.card_container {
  box-shadow: 0 40px 35px rgb(38 42 76 / 10%);
  border-radius: 10px;
}

.card_container:hover {
  box-shadow: none;
}

.card_container:hover .card_wrapper:after {
  width: 100%;
  background-color: #0cd15b;
}

.card_wrapper:hover {
  margin-top: 10px;
}
