.Collapse {
    width: 100%;
    transition: 500ms;
    position: fixed;
    z-index: 2;
    background-color: white;
}

.CollapseCard {
    width: 100%;
    transition: 500ms;
    background-color: white;
}